<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="12">
        <data-table
          :headers="headers"
          :items="obCollection.getModelList()"
          :last-page="serverLastPage"
          :loading="bLocalLoading"
          :options="pagination"
          :total="serverItemsLength"
          btn-action-item-key-id="id"
          btn-action-path="paymentterms"
          hide-btn-action-view
          is-route-name
          @delete="deleteItem"
          @update:sort-by="onSortBy"
          @update:sort-desc="onSortDesc"
          @update:options="onPagination"
          @update:page="onPage"
        >
          <template #[`item.active`]="{ item }">
            <active-icon :active="item.active" />
          </template>

          <template #[`item.can_modify_date`]="{ item }">
            <active-icon :active="item.can_modify_date" />
          </template>

          <template #[`item.name`]="{ item }">
            <v-btn
              :to="{ name: 'paymentterms.update', params: { id: item.id } }"
              color="primary"
              text
            >
              {{ item.name }}
            </v-btn>
          </template>

          <template #[`item.cash`]="{ item }">
            <active-icon :active="item.cash" />
          </template>

          <template #[`item.type`]="{ item }">
            <span v-if="item.cash">-</span>
            <span v-else v-text="$t(`paymentterm.${item.type}`)" />
          </template>

          <template #[`item.frequency`]="{ item }">
            <span v-if="item.cash">-</span>
            <span v-else v-text="$t(item.frequency)" />
          </template>

          <template #[`item.actions`]="{ item }">
            <module-actions :value="item.id" @delete="deleteItem" />
          </template>
        </data-table>
      </v-col>
    </v-row>

    <paymentterms-form
      v-if="obItem"
      :key="$route.params.id"
      v-model="obItem"
      :open.sync="displayForm"
    />
  </v-col>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import PaymenttermsMixin from "@/modules/paymentterms/mixins/PaymenttermsMixin";
import ActiveIcon from "@/components/common/ActiveIcon.vue";
import PaymenttermsForm from "@/modules/paymentterms/components/Form.vue";
import { DataTableHeader } from "vuetify";
import Utils from "@/services/Utils";
import { PaymentTerm } from "@planetadeleste/vue-mc-gw";

@Component({
  components: {
    ActiveIcon,
    PaymenttermsForm,
  },
})
export default class PaymenttermsList extends Mixins(PaymenttermsMixin) {
  isList = true;

  onMounted() {
    const arHeaders: DataTableHeader[] = [
      // { text: "paymentterm.cash", value: "cash", sortable: false },
      // { text: "paymentterm.type", value: "type", sortable: false },
      {
        text: "can.modify.due.date",
        value: "can_modify_date",
        sortable: false,
      },
      { text: "paymentterm.frequency", value: "frequency", sortable: false },
      {
        text: "paymentterm.cycles",
        value: "cycles",
        sortable: false,
      },
      { text: "paymentterm.delay", value: "delay", sortable: false },
    ];
    this.addDTHeaders(arHeaders);

    this.index();
  }

  onRegisterEvents() {
    this.addEvent("before.show.form", this.config);
  }

  config(obItem: PaymentTerm) {
    if (obItem.id || obItem.code) {
      return;
    }

    this.obItem.set("type", "fixed");
    this.obItem.set("cash", false);
    this.obItem.set("active", true);

    Utils.nextCode("paymentterm").then((response) => {
      this.obItem.set("code", response.data);
    });
  }
}
</script>
